import { useState, useEffect } from 'react';
import defaultLang from '@content/translations/default.json';
import { useAppSelector } from '@hooks/redux-hooks';
import mdxStub from '@utils/mdxStub';
import { AllowedGameElementNames } from './../../types';

// this is so we have consistent tests
function areWeTestingWithJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

/** Find a translation from our game translation file */
const useTranslation = () => {
  const [langFile, setLangFile] = useState(defaultLang || 'en');
  const { currentLanguage, defaultLanguage } = useAppSelector(
    s => s.gameState || 'en',
  );

  useEffect(() => {
    async function fetchFile() {
      const file = await import(
        `@content/translations/${currentLanguage}.json`
      );
      setLangFile(file.default);
    }
    if (currentLanguage === defaultLanguage) {
      setLangFile(defaultLang);
    } else {
      fetchFile();
    }
  }, [currentLanguage, defaultLanguage]);
  /** translate in place using string lookup */
  const t = (
    lookupKey: AllowedGameElementNames,
    /** Return an mdx renderer compatible string */
    isMdx?: boolean,
  ) => {
    if (areWeTestingWithJest()) {
      return lookupKey;
    }
    if (langFile) {
      const textLookup = langFile?.[lookupKey];
      if (textLookup) {
        if (isMdx) {
          return textLookup?.childMdx?.body || '🚫NO TRANSLATION🚫';
        }
        return textLookup?.childMdx?.rawBody || '🚫NO TRANSLATION🚫';
      }
    }
    console.warn(`Could not find translation string for ${lookupKey}`);
    // if we could not find it, just return the input string as is
    if (isMdx) {
      return mdxStub;
    }
    return `🚫${lookupKey}🚫`;
  };

  /** get translation with an object already provided, this object
   * must conform to the type "content"
   */
  const g = (lookupObject: any | undefined, isMdx?: boolean) => {
    if (lookupObject) {
      if (isMdx) {
        return lookupObject?.[currentLanguage]?.childMdx?.body || mdxStub;
      }
      return (
        lookupObject?.[currentLanguage]?.childMdx?.rawBody ||
        `🚫NO TRANSLATION🚫`
      );
    }
    return null;
  };
  return { t, g };
};

export default useTranslation;
