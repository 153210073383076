// making string representation of the data purposefully obtuse, count by https://memory-alpha.fandom.com/wiki/Phonetic_alphabet
const S_ID = 'able';
const R_COUNT = 'baker';

// to appease gatsby
const isBrowser = typeof window !== `undefined`;

export const getLocalStoreSessionId = () =>
  isBrowser && localStorage?.getItem(S_ID);
export const setLocalStoreSessionId = (s: string) =>
  isBrowser && localStorage?.setItem(S_ID, s);

export const getLocalStoreReplayCount = () => {
  const count = isBrowser && localStorage?.getItem(R_COUNT);
  return count ? Number(count) : 1;
};
export const setLocalStoreReplayCount = (r: number) =>
  isBrowser && localStorage?.setItem(R_COUNT, r.toString());
