import React, { useEffect, useRef, useContext } from 'react';
import { motion, useSpring, useTransform } from 'framer-motion';
import styled, { ThemeContext } from 'styled-components';
import css from '@styled-system/css';
import { useToggleState } from '@react-stately/toggle';
import { ToggleProps } from '@react-types/checkbox';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useFocusRing } from '@react-aria/focus';
import { useSwitch } from '@react-aria/switch';
import { Flex, Box } from '../Primitives';
import { Label } from '../Typography';

interface CustomToggleProps extends ToggleProps {
  reduceMotion: boolean;
  onLabel: string;
  offLabel: string;
}

const ToggleBackground = styled(Flex)(({ $isFocusVisible, isSelected }) =>
  css({
    '&:hover': {
      // TODO: figure out theming convention for latter
      background: isSelected
        ? 'inherit'
        : `hsla(147, 20%, 24%, 0.1) !important`,
    },
    width: '58px',
    borderRadius: '50px',
    p: '4px',
    cursor: 'pointer',
    border: '1px solid',
    height: '36px',
    boxShadow: t =>
      $isFocusVisible ? `0 0 0 2pt ${t.colors.foreground}` : 'none',
  }),
);

const ToggleControlText = styled(Label).attrs({ variant: 'l4' })({
  display: 'flex',
  flex: 1,
});

const ToggleCircle = styled(Box)(
  css({
    width: '26px',
    height: '26px',
    backgroundColor: 'surface',
    borderRadius: '56px',
    border: '1px solid',
  }),
);

const Toggle = (props: CustomToggleProps) => {
  const { reduceMotion = false, onLabel, offLabel } = props;
  const themeContext = useContext(ThemeContext);
  const ref = useRef();
  const state = useToggleState(props);
  const { inputProps } = useSwitch(props, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  // this is the x offset for the toggle
  const x = useSpring(0, { type: 'spring', stiffness: 700, damping: 30 });
  const xPercent = useTransform(x, value => `${value}%`);
  const background = useTransform(
    x,
    [0, 100],
    [themeContext.colors.surface, themeContext.colors.systemAccent],
  );

  const toggleControlTextVariants = {
    toggled: { x: '-106%', opacity: reduceMotion ? [0, 1] : 1 },
    notToggled: { x: 0, opacity: reduceMotion ? [0, 1] : 1 },
  };

  useEffect(() => {
    if (state.isSelected) {
      x.set(94);
    } else {
      x.set(0);
    }
  }, [props, state.isSelected, x]);
  return (
    <Flex as="label" alignItems="center">
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <ToggleBackground
        isSelected={state.isSelected}
        aria-hidden="true"
        $isFocusVisible={isFocusVisible}
        as={motion.div}
        style={{
          backgroundColor: background,
        }}
      >
        <ToggleCircle
          as={motion.div}
          aria-hidden="true"
          style={{
            x: reduceMotion ? (state.isSelected ? '92%' : '0%') : xPercent,
          }}
        />
        <ToggleControlText
          aria-hidden="true"
          animate={state.isSelected ? 'toggled' : 'notToggled'}
          variants={toggleControlTextVariants}
          as={motion.div}
        >
          {state.isSelected ? onLabel : offLabel}
        </ToggleControlText>
      </ToggleBackground>
      {props.children}
    </Flex>
  );
};

export default Toggle;
