import styled, { StyledComponent } from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  compose,
  VariantArgs,
} from 'styled-system';
import sx from '../utils/sx';

// interface BoxProps extends BorderProps, ColorProps, SpaceProps {}
export type BoxProps =
  | BorderProps
  | ColorProps
  | SpaceProps
  | LayoutProps
  | PositionProps
  | VariantArgs;

/**
 * building block component
 * this enables the use of theming with styled-system
 * https://styled-system.com/table/#reference-table for more information regarding the props
 *
 * sx removes the need to import css from styled-system into each component that uses Box
 */
const Box: StyledComponent<'div', any, BoxProps, never> = styled('div')(
  {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
  },
  compose(border, color, space, layout, position),
  sx,
);

export default Box;
