import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import css from '@styled-system/css';
import BaseText, { BaseTextProps } from '../Primitives/BaseText';
import { Button } from '../Buttons';

interface DisplayProps {
  variant?: 'd1' | 'd2' | 'd3' | 'gameTitle';
  as?: string;
}

const Display: StyledComponent<
  'div',
  any,
  DisplayProps & BaseTextProps,
  never
> = styled(BaseText)(
  ({ sx }) =>
    css({
      lineHeight: '100%',
      fontFamily: 'fun',
      color: 'foreground',
      fontStyle: 'normal',
      fontWeight: '400',
      letterSpacing: '.18px',
      ...sx,
    }),
  variant({
    variants: {
      d1: {
        fontSize: [15, 16, 17],
      },
      d2: {
        fontSize: [11, 12, 13],
      },
      d3: {
        fontSize: [6, 8, 10],
      },
      gameTitle: {
        fontSize: [8, 14, 17],
      },
    },
  }),
);

Display.defaultProps = {
  variant: 'd2',
  as: 'label',
};

export default Display;
