import fisherYatesShuffle from './fisherYatesShuffle';

// ok I really need to think of a better name
// basically gives you back the modified array that includes puppet values (null) and also gives you what you removed along with the indices
const yankAndNullifyArrayItems = (
  arr,
  whatToRemove,
  shouldShuffleYankedItems,
) => {
  const removedIndices = [] as number[];
  let removedItems = [] as { [key: string]: unknown }[];
  let tmpArr = [...arr];

  tmpArr = tmpArr.reduce((acc, currentValue, currentIndex) => {
    // remove any items that contain our matcher, or if our matcher is true, remove all non falsy
    if (
      currentValue &&
      (currentValue[whatToRemove?.key] === whatToRemove?.value ||
        whatToRemove === true)
    ) {
      removedIndices.push(currentIndex);
      removedItems.push(currentValue);
      acc.push(null);
    } else {
      acc.push(currentValue);
    }
    return acc;
  }, [] as any);

  if (shouldShuffleYankedItems) {
    removedItems = fisherYatesShuffle(removedItems);
  }

  const reinsertItems = arrToInsertInto => {
    if (removedItems.length > 0) {
      // reinsert the softpegs we took out (in the same order)
      removedIndices.forEach((indiceToInsert, index) => {
        arrToInsertInto.splice(indiceToInsert, 1, removedItems[index]);
      });
    }
  };

  return {
    tmpArr,
    reinsertItems,
  };
};

interface props {
  array: { [key: string]: unknown }[] | { [key: null]: unknown }[];
  /** A special key inside one of our objects in the array that marks the item as "unmovable", normally all shuffling is ignored if this key is found to be TRUE */
  peg: {
    key: string;
    value: boolean;
  } | null;
  /** A softpeg is a key/value pair that we want to remain in place, but be shuffled with its peers (other objects that contain the softpeg). It cannot contain an item with the pegged key that we see above, as that peg overrides any of this logic */
  softPeg?: {
    key: string;
    value: string;
  } | null;
  /** of the found softpegs, the algorithm will try to mutually shuffle them if this is true */
  shouldShuffleSoftPegs: boolean;
  /** of the rest of the stuff, anything that is not a peg or softpeg, the algorithm will try to mutually shuffle them as well */
  shouldShuffleRemaining: boolean;
}

/** shuffle the array, except for the peg (the specified object key that we do not want to shuffle if it is true) or the softPeg (logic inside) */
const fixedShuffle = ({
  array = [],
  shouldShuffleSoftPegs = true,
  shouldShuffleRemaining = true,
  peg = null,
  softPeg = null,
}: props): object[] => {
  // copy array so we do not accidentally mutate what is being passed in
  let modArray = [...array];
  // for a fixedShuffle, a peg is always required, otherwise its just a regular shuffle
  if (peg.length === 0) {
    throw new Error('Please specify a peg');
  }

  // try to do our regularly pegged items
  const { tmpArr: tmpPeggedArr, reinsertItems: reinsertPeggedItems } =
    yankAndNullifyArrayItems(modArray, peg, false);
  modArray = tmpPeggedArr;

  const { tmpArr: tmpSoftPeggedArr, reinsertItems: reinsertSoftPeggedItems } =
    yankAndNullifyArrayItems(modArray, softPeg, shouldShuffleSoftPegs);
  modArray = tmpSoftPeggedArr;

  // run the yates shuffle on array, occluding our pegged and softPegged items
  const { tmpArr: tmpRestArr, reinsertItems: reinsertRestOfItems } =
    yankAndNullifyArrayItems(modArray, true, shouldShuffleRemaining);
  modArray = tmpRestArr;

  reinsertPeggedItems(modArray);
  reinsertSoftPeggedItems(modArray);
  reinsertRestOfItems(modArray);

  return modArray;
};

export default fixedShuffle;
