import { configureStore } from '@reduxjs/toolkit';
import soundsMiddleware from 'redux-sounds';
import sounds from '@content/sounds.json';
import routeReducer, { initialRouteState } from './slices/routeSlice';
import gameStateReducer, { initialGameState } from './slices/gameStateSlice';
import loggingReducers, { initialLoggingState } from './slices/loggingSlice';
import levellingReducer, {
  initialLevellingState,
} from './slices/levellingSlice';
import loggingMiddleware from './middleware/loggingMiddleware';

export type SoundsDataKeys = 'Music' | 'Button' | 'Incorrect' | 'Correct';

const loadedSoundsMiddleware = soundsMiddleware({
  // stub basically all the music/sounds as we want to load it on demand
  // items that load on home page land
  Button: sounds.Button,
  // all lazy loaded items are here, but are stubbed with the button sound for now
  // Music: sounds.Button,
  // Incorrect: sounds.Button,
  // Correct: sounds.Button,
});
const loadedLoggingMiddleware = loggingMiddleware();

const store = ({ allRoutes = [] } = {}) =>
  configureStore({
    preloadedState: {
      route: {
        ...initialRouteState,
        // these are loaded at gatsby build time, but allRoutes will change when app inits based on game config
        allRoutes,
        allOriginalRoutes: allRoutes,
      },
      gameState: {
        ...initialGameState,
      },
      levelling: {
        ...initialLevellingState,
        // from our routes, count the number of claims
        totalClaims: allRoutes.filter(e => e.typeOfScreen === 'Claim').length,
      },
      logging: {
        ...initialLoggingState,
      },
    },
    reducer: {
      route: routeReducer,
      gameState: gameStateReducer,
      levelling: levellingReducer,
      logging: loggingReducers,
    },
    middleware: getDefaultMiddleware => {
      // ignore middleware for tests
      if (process.env.NODE_ENV === 'test') {
        return getDefaultMiddleware();
      }
      return getDefaultMiddleware().concat([
        loadedSoundsMiddleware,
        loadedLoggingMiddleware,
      ]);
    },
  });

const dummyStore = store();
export type RootState = ReturnType<typeof dummyStore.getState>;
export type AppDispatch = typeof dummyStore.dispatch;

export default store;
