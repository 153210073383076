import React from 'react';
import Button, { ButtonProps } from './Button';
import Icon, { IconProps } from '../Primitives/Icon';

export interface IconButtonProps extends IconProps {
  showOutline?: boolean;
  styledImageProps?: object;
}

const IconButton = (props: IconButtonProps & ButtonProps) => {
  const { iconName, iconSrc, styledImageProps, ...delegated } = props;
  return (
    <Button
      {...delegated}
      variant={props.variant || 'icon'}
      buttonSize={props.buttonSize || 'unset'}
    >
      <Icon iconName={iconName} iconSrc={iconSrc} {...styledImageProps} />
    </Button>
  );
};

export default IconButton;
