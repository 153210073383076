import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import MailTo from '@mdx/MailTo';
import Anchor from '@mdx/Anchor';
import Img from '@mdx/Img';

const shortcodes = { mailto: MailTo, MailTo, a: Anchor, img: Img };

export default ({ children }) => {
  // loosely check if what was passed in is an mdx compliant string
  if (children.includes('isMDXComponent = true')) {
    return (
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    );
  }
  return children;
};
