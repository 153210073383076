import gameConfig from '@content/gameconfig';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  doc,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import { setLocalStoreSessionId } from './localStore';

const rootCollection = 'sessions';

initializeApp({
  apiKey: process.env.GATSBY_FIRESTORE_API_KEY,
  projectId: process.env.GATSBY_FIRESTORE_PROJECT,
});
const db = getFirestore();

export interface OptionalSessionData {
  surveyName?: string;
  language?: string;
}

/** initialize a unique entry into our firebase store */
export const initSession = async ({
  surveyName,
  language,
}: OptionalSessionData = {}): Promise<string> => {
  const streamTableNames = gameConfig.Stream_Table_Names;
  const refObj = {
    created: new Date(),
    host: window?.location?.host,
    language:
      language ||
      gameConfig.Languages_Used?.[0]?.Content_Type ||
      gameConfig.Languages_Used?.Content_Type,
    survey_name: surveyName || gameConfig.Version,
    // basically the hash
    version: window.location.hash.replace('#', ''),
    // this is subject to change as game continues (provided we are using profiler)
    stream_name: Array.isArray(streamTableNames)
      ? streamTableNames[0]
      : streamTableNames,
  };
  const docRef = await addDoc(collection(db, rootCollection), refObj);
  console.info(
    '%c 🔎 For transparency, you can see all the data that we log below 🔎 ',
    'color: red',
  );
  console.info('LOG: ', refObj);
  // this id is used as our unique session id
  // if the user refreshing, the id is gone and user is kicked back
  const sessionId = docRef.id;
  // we must also store this id in local for retrieval in case user resets
  setLocalStoreSessionId(sessionId);
  return sessionId;
};

/** each entry in our db has these in common */
export interface CommonLogData {
  // this is optional, and is the name of the content type entry on airtable
  question_name?: string;
  // question_index: number;
  step_counter?: number;
}

export interface Answers {
  /* text is weird, we can say, this is more like the value (TODO: change name later) */
  answer_text: string;
  duration_in_seconds: number;
  // effectively the name that is supplied on airtable, it contains the type in there as well
  question_type: string;
  /** its boolean because our claim answers are mainly booleans */
  // chosen_answer_value: boolean;
  // the last option here is for when you are using this interface in claims, claims have no results
  result: 'correct' | 'incorrect' | '';
  collection_name: 'answers';
}

// export interface Interrupt {
//   question_type: string;
//   answer_text: string;
//   chosen_answer_value: boolean | string;
//   duration_in_seconds: number;
//   collection_name: 'interrupts';
// }

interface Correction {
  comments: string;
  source_url: string;
  collection_name: 'corrections';
}

interface Demographic {
  /** this can be an array as well because demo questions can have multiple options */
  answers: string[] | object;
  collection_name: 'demographics';
}

interface Event {
  event_type:
    | 'click'
    | 'open_context_menu'
    | 'toggle_lang'
    | 'set_stream'
    | 'toggle_settings'
    | 'session_kicked'
    | 'rank_up'
    | 'rank_down'
    | 'consent'
    | 'reset_game'
    | 'finished_game'
    | 'error'
    | 'session_init_ms'
    | 'navigate';
  location?: string;
  /** a little more specific than just the question name, can be descriptive such as "help button" etc. */
  target: string;
  collection_name: 'events';
}

export type LogData = Answers | Correction | Demographic | Event;

export const log = async (
  data: LogData & CommonLogData,
  sessionId: string | null,
) => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  const docRef = collection(
    db,
    rootCollection,
    sessionId || 'error',
    data.collection_name,
  );
  /** NOTE: why is this in prod? This represents transparency. Anyone can know what we intake.
   * This transparency is similar to seeing your debug log on a variety of apps and services
   */
  console.info('LOG: ', data);
  const { collection_name, ...dataMinusCollectionName } = data;
  await addDoc(docRef, { ...dataMinusCollectionName });
};

export const batchLog = async (
  data: LogData[] & CommonLogData[],
  sessionId: string | null,
) => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  // // Get a new write batch TODO: batching does not seem to work...for now
  // I asked SO https://stackoverflow.com/questions/70160877/how-to-batch-adddoc-in-firestore
  // const batch = writeBatch(db);

  // // for each data, set the batch
  // data.forEach(datum => {
  //   const docRef = collection(
  //     db,
  //     rootCollection,
  //     sessionId || 'error',
  //     datum.collection_name,
  //   );
  //   batch.set(docRef, doc);
  // });

  // await batch.commit();

  const promiseBatch = data.map(datum => {
    const docRef = collection(
      db,
      rootCollection,
      sessionId || 'error',
      datum.collection_name,
    );
    const { collection_name, ...datumMinusCollectionName } = datum;
    console.info('LOG: ', datumMinusCollectionName);
    return addDoc(docRef, { ...datumMinusCollectionName });
  });

  await Promise.all(promiseBatch);
};

export const setLanguage = async (
  language: string,
  sessionId: string | null,
) => {
  const docRef = doc(db, rootCollection, sessionId);
  await updateDoc(docRef, { language });
};

export const setStreamName = async (
  streamName: string,
  sessionId: string | null,
) => {
  const docRef = doc(db, rootCollection, sessionId);
  await updateDoc(docRef, { stream_name: streamName });
};

export const setGameComplete = async (sessionId: string | null) => {
  const docRef = doc(db, rootCollection, sessionId);
  await updateDoc(docRef, { has_completed_game: true });
};
