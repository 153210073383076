import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Flex, Label } from 'workspace-core-ui';

// converted using https://www.bayustudio.com/app/svg-encoder/
const generateStarSvg = (
  width = 45,
  height = 45,
  bgColor = 'red',
  outlineColor = 'red',
) =>
  `url("data:image/svg+xml,%3csvg 
  xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}' viewBox='0 0 ${
    width + 1
  } ${height + 1}' %3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:${encodeURIComponent(
    outlineColor,
  )}%7d%3c/style%3e%3c/defs%3e%3cg id='Layer_2' data-name='Layer 2'%3e%3cg id='Layer_1-2' data-name='Layer 1'%3e%3cg id='Star'%3e%3cpath id='shadow' class='cls-1' d='M46 22.79c1.94-1.93.87-8-1.81-8.36l-.65.07h.12a3.36 3.36 0 0 1 1.81 5.69l-7.39 7.36a3.38 3.38 0 0 0-.94 3l1.75 10.38a3.29 3.29 0 0 1-4.74 3.52L25 39.51a3.16 3.16 0 0 0-3 0l-9.12 4.9a3.29 3.29 0 0 1-4.74-3.52l1.72-10.38a3.35 3.35 0 0 0-.94-3L1.54 20.2a3.35 3.35 0 0 1 1.62-5.64H2.8C.12 14.92-.95 20.3 1 22.23l7.93 7.91c.77.77.17 1.88 0 3L7 43.48A3.29 3.29 0 0 0 11.72 47L22 42.09a3.21 3.21 0 0 1 3 0L35.28 47A3.28 3.28 0 0 0 40 43.48L38.19 33.1c-.19-1.08-.88-2.19-.11-3Z'/%3e%3cpath id='shape' d='M26.43 2.32 31 11.57a3.27 3.27 0 0 0 2.46 1.78l10.21 1.48a3.27 3.27 0 0 1 1.81 5.57l-7.39 7.2a3.27 3.27 0 0 0-.94 2.89l1.75 10.16a3.27 3.27 0 0 1-4.74 3.44L25 39.3a3.27 3.27 0 0 0-3 0l-9.12 4.79a3.26 3.26 0 0 1-4.74-3.44l1.72-10.16a3.24 3.24 0 0 0-.94-2.89l-7.38-7.2a3.27 3.27 0 0 1 1.81-5.57l10.2-1.48A3.24 3.24 0 0 0 16 11.57l4.57-9.25a3.26 3.26 0 0 1 5.86 0Z' style='fill:${encodeURIComponent(
    bgColor,
  )}'/%3e%3cpath id='stroke' class='cls-1' d='M11.34 45a3.77 3.77 0 0 1-3.71-4.4l1.74-10.19A2.75 2.75 0 0 0 8.58 28l-7.39-7.2a3.76 3.76 0 0 1 2.09-6.42l10.2-1.48a2.76 2.76 0 0 0 2.08-1.52l4.57-9.28a3.76 3.76 0 0 1 6.75 0l4.56 9.24a2.79 2.79 0 0 0 2.09 1.52l10.2 1.48a3.76 3.76 0 0 1 2.09 6.42L38.43 28a2.75 2.75 0 0 0-.79 2.45l1.74 10.16a3.77 3.77 0 0 1-5.46 4l-9.13-4.8a2.78 2.78 0 0 0-2.57 0l-9.13 4.8a3.77 3.77 0 0 1-1.75.39ZM23.5 1A2.73 2.73 0 0 0 21 2.54l-4.56 9.25a3.78 3.78 0 0 1-2.83 2.06L3.42 15.33a2.77 2.77 0 0 0-1.53 4.72l7.38 7.19a3.77 3.77 0 0 1 1.09 3.34L8.61 40.74a2.77 2.77 0 0 0 4 2.91l9.12-4.8a3.82 3.82 0 0 1 3.51 0l9.12 4.8a2.76 2.76 0 0 0 4-2.91l-1.71-10.16a3.79 3.79 0 0 1 1.08-3.34l7.39-7.19a2.77 2.77 0 0 0-1.54-4.72l-10.2-1.48a3.78 3.78 0 0 1-2.83-2.06L26 2.54A2.71 2.71 0 0 0 23.5 1Z'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e")`;

const Wrapper = styled(Flex)(
  css({
    backgroundRepeat: 'no-repeat',
    backgroundImage: ({ colors }) =>
      generateStarSvg(47, 47, colors.secondaryAccent, colors.foreground),
    height: '47px',
    width: '47px',
    placeContent: 'center',
    isolation: 'isolate',
  }),
);

const Count = styled(Label).attrs({ variant: 'l2' })(
  css({
    color: 'text',
    width: '100%',
  }),
);

interface LevelStarProps {
  count: number;
  isLevellingUp: boolean;
  reducedMotion?: boolean;
}

const LevelStar = ({
  count = 1,
  isLevellingUp,
  reducedMotion = false,
  ...delegated
}: LevelStarProps) => {
  const controls = useAnimation();
  useEffect(() => {
    controls.start({
      scale: 1,
      rotate: 0,
      // transition: {
      //   type: 'spring',
      //   velocity: 25,
      //   stiffness: 700,
      //   damping: 80,
      // },
    });
    if (isLevellingUp) {
      controls.start({
        rotate: reducedMotion ? 0 : [0, 0, 360, 360, 360],
        scale: [1, 2, 2, 1, 1],
        transition: {
          duration: 2,
          ease: 'easeInOut',
        },
      });
    }
  }, [count, controls, isLevellingUp, reducedMotion]);
  return (
    <Wrapper as={motion.div} animate={controls} {...delegated}>
      <Count>{isLevellingUp ? count + 1 : count}</Count>
    </Wrapper>
  );
};

export default LevelStar;
