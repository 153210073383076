import React from 'react';
import { ThemeProvider } from 'styled-components';
import defaultSystemTheme from './theme';

// TODO: the merging of default user theme with custom user theme that comes from airtable can be done in gatsby node itself
// TODO: Can change later to fit different themes
const CustomThemeProvider = ({
  children,
  customColors,
}: {
  children: React.ReactNode;
  customColors: object;
}): React.ReactElement => (
  <ThemeProvider
    theme={{
      ...defaultSystemTheme,
      colors: customColors || defaultSystemTheme.colors,
    }}
  >
    {children}
  </ThemeProvider>
);
export default CustomThemeProvider;
