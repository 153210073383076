import React from 'react';
import { AnimatePresence } from 'framer-motion';
import wrapWithProvider from './src/redux/ProviderWrapper';
import Footer from './src/containers/Footer';
import Header from './src/containers/Header';
import NotificationSystem from './src/containers/NotificationSystem';
import './src/styles/global.css';
import '@fontsource/manrope/variable.css';
import '@fontsource/luckiest-guy';

export const wrapRootElement = wrapWithProvider;

export const wrapPageElement = ({ element }) => (
  <>
    <Header />
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
    <Footer />
    <NotificationSystem />
  </>
);
