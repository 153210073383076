import { IGatsbyImageData } from 'gatsby-plugin-image';

// TODO: type safe because return types are variable
interface GatbsyImage {}

/** access the meat of our symbol data */
const standardSymbolDigger = s =>
  s?.nodes?.[0]?.data?.Content[0]?.data?.Symbol?.localFiles[0];

const getSymbol = (
  symbolNode: any,
  symbolType: 'svg' | 'gatsbyImage' | 'staticImage',
) => {
  const s = standardSymbolDigger(symbolNode);
  // just return us the zeroth nested match of whatever you passed in
  if (symbolType === 'svg') {
    return s.childSvg.content;
  }
  if (symbolType === 'gatsbyImage') {
    return s.childImageSharp.gatsbyImageData;
  }

  if (symbolType === 'staticImage') {
    // our access path here is a little different
    return s?.publicURL;
  }
};

export default getSymbol;
