import React, { useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Tray, IconButton, Para, Button, Flex, Label } from 'workspace-core-ui';
import { useOverlayTriggerState } from '@react-stately/overlays';
import useTranslation from '@hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '@hooks/redux-hooks';
import getSymbol from '@utils/getSymbol';
import { logItem } from '@slices/loggingSlice';
import ShareModalButton from './ShareModalButton';
import CustomMdxRenderer from './CustomMdxRenderer';

interface AboutTrayButtonProps {
  buttonProps?: any;
}

const AboutTrayButton = (props: AboutTrayButtonProps) => {
  const data = useStaticQuery(graphql`
    query aboutModalImage {
      aboutSymbols: allAirtable(
        filter: {
          table: { eq: "Game Elements" }
          data: { Name: { eq: "About Modal" } }
        }
      ) {
        ...GatsbyImageGetFragment
      }
    }
  `);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { buttonProps } = props;
  const overlayState = useOverlayTriggerState({});
  const { hasStarted } = useAppSelector(e => e.gameState);
  const aboutModalBgImg = getSymbol(data.aboutSymbols, 'gatsbyImage');
  // control states

  const TrayHeader = useCallback(
    () => (
      <Flex p={[4, 6, 6]}>
        <Flex flex={1}>
          <IconButton
            data-cy="closeAboutModalIcon"
            onPress={() => overlayState.close()}
            showOutline={false}
            iconName="close"
          >
            Close
          </IconButton>
        </Flex>
        <ShareModalButton modalTitle={t('Share Modal Header')} />
      </Flex>
    ),
    [overlayState, t],
  );

  return (
    <>
      <IconButton
        onPress={() => {
          dispatch(
            logItem({
              collection_name: 'events',
              event_type: 'click',
              target: `about modal open button`,
            }),
          );
          overlayState.open();
        }}
        data-cy="aboutButton"
        iconName="info"
        // TODO: localize ARIA
        aria-label="about"
        {...buttonProps}
      />
      <Tray
        title={t('Share Modal Header')}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
        image={aboutModalBgImg}
        showBgImage={!hasStarted}
        HeaderComponent={TrayHeader}
        isDismissable
      >
        <Para as="span" variant="p3">
          <CustomMdxRenderer>{t('About Modal', true)}</CustomMdxRenderer>
        </Para>
        <Flex mt={8}>
          <Button data-cy="closeAboutModalButton" onPress={overlayState.close}>
            <Label variant="l1">{t('About Modal Back Button')}</Label>
          </Button>
        </Flex>
      </Tray>
    </>
  );
};

export default AboutTrayButton;
