import React, { forwardRef, useRef } from 'react';
import {
  useOverlay,
  usePreventScroll,
  useModal,
  OverlayContainer,
} from '@react-aria/overlays';
import { ModalProps } from '@react-types/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import styled from 'styled-components';
import css from '@styled-system/css';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex } from '../Primitives';
import { Heading, Label } from '../Typography';
import { Button } from '../Buttons';

interface LocalModalProps extends ModalProps {
  title: string;
  showClose?: boolean;
  closeLabel?: string;
  modalBodyStyles?: object;
  reduceMotion?: boolean;
  shouldAutoFocus?: boolean;
}

const Wrapper = styled(Flex)(
  css({
    position: 'fixed',
    zIndex: 'skyscraper',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const Body = styled(Flex)(
  css({
    flexDirection: 'column',
    minWidth: ['272px', '324px', '324px'],
    backgroundColor: 'surface',
    color: 'black',
    position: 'absolute',
    p: [6, 6, 7],
    m: [3, 0, 0],
    borderRadius: '8px',
  }),
);

const Modal = forwardRef((props: LocalModalProps, ref = null) => {
  const {
    title = 'dummy',
    showClose,
    reduceMotion = false,
    shouldAutoFocus = true,
    children,
  } = props;
  const internalRef = useRef(null);
  const customModalRef = ref || internalRef;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps, underlayProps } = useOverlay(props, customModalRef);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  const { dialogProps, titleProps } = useDialog(props, customModalRef);
  const yOffsets = reduceMotion ? 0 : 50;

  return (
    <AnimatePresence>
      {props.isOpen && (
        <OverlayContainer>
          <Wrapper
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            {...underlayProps}
          >
            <FocusScope contain restoreFocus autoFocus={shouldAutoFocus}>
              <Body
                {...overlayProps}
                {...dialogProps}
                {...modalProps}
                {...props.modalBodyStyles}
                ref={customModalRef}
                as={motion.div}
                initial={{ y: yOffsets, opacity: 0 }}
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                exit={{
                  y: -yOffsets,
                  opacity: 0,
                }}
              >
                <Heading as="h3" variant="h2" {...titleProps} mt={0}>
                  {title}
                </Heading>
                {children}
                {showClose && (
                  // TODO: make this horizontal aligned
                  <Button
                    buttonSize="medium"
                    variant="ghost"
                    key="close-btn"
                    mt={4}
                    onPress={() => {
                      setTimeout(() => {
                        props.onClose();
                      }, 0);
                    }}
                  >
                    <Label textDecoration="underline" variant="l3">
                      {props.closeLabel}
                    </Label>
                  </Button>
                )}
              </Body>
            </FocusScope>
          </Wrapper>
        </OverlayContainer>
      )}
    </AnimatePresence>
  );
});

export default Modal;
