import React from 'react';
import Box from './Box';
// import sprite from "../assets/svg/allSprites.svg"
import chevronUpSprite from '../assets/svg/chevron-up.svg';
import closeSprite from '../assets/svg/close.svg';
import infoSprite from '../assets/svg/info.svg';
import musicSprite from '../assets/svg/music.svg';
import shareSprite from '../assets/svg/share.svg';
import twitterSprite from '../assets/svg/twitter.svg';
import linkSprite from '../assets/svg/link.svg';
import fbSprite from '../assets/svg/fb.svg';

const iconList = [
  'chevron-up',
  'close',
  'info',
  'music',
  'share',
  'twitter',
  'link',
  'fb',
] as const;

// TODO: This is temporary as sprites do not work on safari
// https://github.com/gatsbyjs/gatsby/discussions/35104
const iconMap = {
  'chevron-up': chevronUpSprite,
  close: closeSprite,
  info: infoSprite,
  music: musicSprite,
  share: shareSprite,
  twitter: twitterSprite,
  link: linkSprite,
  fb: fbSprite,
};

export type IconType = typeof iconList[number];

export interface IconProps {
  iconName?: IconType;
  /** if you want to add a custom icon instead of preset ones that we give you */
  iconSrc?: string;
  delegated?: object;
}

const Icon = (props: IconProps) => {
  const { iconSrc, iconName = 'info', ...delegated } = props;
  const verifiedIconName =
    iconSrc || iconList.indexOf(iconName) >= 0 ? iconName : 'info';
  const IconUsed = iconMap[verifiedIconName];
  return (
    <Box
      as={IconUsed}
      sx={{
        width: '28px',
        height:
          iconName === 'fb' || iconName === 'twitter'
            ? '28px'
            : ['16px', '28px'],
      }}
      {...delegated}
    />
  );
};

export default Icon;
