import styled, { StyledComponent } from 'styled-components';
import {
  compose,
  shadow,
  ShadowProps,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
} from 'styled-system';
import Box, { BoxProps } from './Box';

export type BaseTextProps =
  | ShadowProps
  | TextStyleProps
  | TypographyProps
  | BoxProps;

const Text: StyledComponent<'div', any, BaseTextProps, never> = styled(Box)(
  compose(shadow, textStyle, typography),
);
export default Text;
